import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import leads from './leads/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import staff from './staff/reducers'
import tracking from './tracking/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    leads,
    user,
    menu,
    settings,
    staff,
    tracking,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
