import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadbranch,
  loaddepartment,
  loadproduct,
  loadAssets,
  loadProductInfo,
  loadProductDetail,
  saveassets,
  loadAssetsInfo,
  loadAssetDetail,
  createTracking,
  loadtracking,
  loadlocation,
  loadassethistory,
  loadAssetTracking,
  loadCurentTracking,
} from 'api/tracking'
import actions from './actions'

export function* LOAD_BRANCH() {
  const { response } = yield call(loadbranch)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        branchList: response?.data?.branchList,
      },
    })
  }
}

export function* LOAD_LOCATION() {
  const { response } = yield call(loadlocation)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        locationList: response?.data?.locationList,
      },
    })
  }
}

export function* LOAD_DEPARTMENT() {
  const { response } = yield call(loaddepartment)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        departmentList: response?.data?.departmentList,
      },
    })
  }
}

export function* LOAD_PRODUCT() {
  const { response } = yield call(loadproduct)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productListInfo: response?.data?.productList,
      },
    })
  }
}

export function* LOAD_ASSETS() {
  const { response } = yield call(loadAssets)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        assetsListInfo: response?.data?.assetsList,
      },
    })
  }
}

export function* LOAD_TRACKING() {
  const { response } = yield call(loadtracking)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        trackingList: response?.data?.trackingList,
      },
    })
  }
}

export function* LOAD_CURRENT_TRACKING() {
  const { response } = yield call(loadCurentTracking)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        currentTrackingList: response?.data?.currentTrackingList,
      },
    })
  }
}

export function* LOAD_ASSET_TRACKING() {
  const { response } = yield call(loadAssetTracking)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        timeLine: response?.data?.timeLine,
        assetList: response?.data?.assetList,
      },
    })
  }
}

export function* GET_PRODUCT_INFO() {
  const { response } = yield call(loadProductInfo)
  yield put({
    type: actions.SET_STATE,
    payload: {
      displayAddAssetsDrawer: true,
    },
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productInfo: response?.data?.productInfo,
      },
    })
  }
}

export function* GET_ASSETS_INFO() {
  const { response } = yield call(loadAssetsInfo)
  yield put({
    type: actions.SET_STATE,
    payload: {
      displayAddTrackingDrawer: true,
    },
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        assetInfo: response?.data?.assetInfo,
      },
    })
  }
}

export function* LOAD_PRODUCT_DETAILS({ payload }) {
  const { response } = yield call(loadProductDetail, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productDetailInfo: response?.data?.productDetailInfo,
      },
    })
  }
}

export function* GET_ASSET_HISTORY({ payload }) {
  const { response } = yield call(loadassethistory, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        assetHistory: response?.data?.assetHistory,
        displayAssetHistoryDrawer: true,
      },
    })
  }
}

export function* LOAD_ASSET_DETAILS({ payload }) {
  const { response } = yield call(loadAssetDetail, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        assetDetailInfo: response?.data?.assetDetailInfo,
        productDetailInfo: response?.data?.productDetailInfo,
        isCheckinEligible: response?.data?.isCheckinEligible,
        isCheckOutEligible: response?.data?.isCheckOutEligible,
        lastCheckInInfo: response?.data?.lastCheckInInfo,
        currentLocation: response?.data?.currentLocation,
        myLastAction: response?.data?.myLastAction,
      },
    })
  }
}

export function* GET_ASSET_DETAILS({ payload }) {
  const { response } = yield call(loadAssetDetail, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayUpdateAssetsDrawer: true,
        assetDetailInfo: response?.data?.assetDetailInfo,
        productDetailInfo: response?.data?.productDetailInfo,
        isCheckinEligible: response?.data?.isCheckinEligible,
        isCheckOutEligible: response?.data?.isCheckOutEligible,
        lastCheckInInfo: response?.data?.lastCheckInInfo,
        currentLocation: response?.data?.currentLocation,
        myLastAction: response?.data?.myLastAction,
      },
    })
  }
}

export function* CREATE_ASSETS({ payload }) {
  const { response } = yield call(saveassets, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        assetsListInfo: response?.data?.assetsList,
        displayAddAssetsDrawer: false,
      },
    })
  }
}

export function* CREATE_TRACKING({ payload }) {
  const { response } = yield call(createTracking, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddTrackingDrawer: false,
        assetDetailInfo: null,
        productDetailInfo: null,
        isCheckinEligible: null,
        isCheckOutEligible: null,
        lastCheckInInfo: null,
        is_logged_in: null,
        is_logged_out: null,
        myLastAction: null,
      },
    })
    yield call(LOAD_TRACKING)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_BRANCH, LOAD_BRANCH),
    takeLatest(actions.GET_ASSET_HISTORY, GET_ASSET_HISTORY),
    takeLatest(actions.LOAD_DEPARTMENT, LOAD_DEPARTMENT),
    takeLatest(actions.LOAD_LOCATION, LOAD_LOCATION),
    takeLatest(actions.LOAD_PRODUCT, LOAD_PRODUCT),
    takeLatest(actions.LOAD_ASSETS, LOAD_ASSETS),
    takeLatest(actions.GET_PRODUCT_INFO, GET_PRODUCT_INFO),
    takeLatest(actions.LOAD_PRODUCT_DETAILS, LOAD_PRODUCT_DETAILS),
    takeLatest(actions.LOAD_ASSET_DETAILS, LOAD_ASSET_DETAILS),
    takeLatest(actions.CREATE_ASSETS, CREATE_ASSETS),
    takeLatest(actions.GET_ASSETS_INFO, GET_ASSETS_INFO),
    takeLatest(actions.CREATE_TRACKING, CREATE_TRACKING),
    takeLatest(actions.LOAD_TRACKING, LOAD_TRACKING),
    takeLatest(actions.LOAD_ASSET_TRACKING, LOAD_ASSET_TRACKING),
    takeLatest(actions.LOAD_CURRENT_TRACKING, LOAD_CURRENT_TRACKING),
    takeLatest(actions.GET_ASSET_DETAILS, GET_ASSET_DETAILS),
  ])
}
