import React, { Fragment } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ user, settings }) => ({ user, settings })
let previousPath = ''

const Layout = ({ user, /*  settings, */ children, location: { pathname, search } }) => {
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  const authLayouts = [
    '/confirm-email',
    '/login',
    '/recover-password',
    '/reset-password',
    '/lockscreen',
    '/404',
    '/500',
    '/staff',
  ]

  const publicLayouts = ['/register']

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/' || publicLayouts.includes(pathname)) {
      return 'public'
    }
    if (authLayouts.includes(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.accessToken
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'
  const isPublicLayout = getLayout() === 'public'

  const BootstrappedLayout = () => {
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    if (!isAuthLayout && !isUserAuthorized && !isPublicLayout) {
      return <Redirect to="/login" />
    }
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="%s | TracM" />

      {/* <Helmet titleTemplate={`%s | ${settings.appName}`} title={`${settings.appName}`} /> */}

      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
