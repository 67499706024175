/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  currentTrackingList: null,
  myLastAction: null,
  currentAssetAction: null,
  currentLocation: null,
  lastCheckInInfo: null,
  trackingList: null,
  leadArrayCount: null,
  leadslistinfo: null,
  assetInfo: null,
  assetList: null,
  timeLine: null,
  selectedData: null,
  productInfo: null,
  productListInfo: null,
  leadnotesinfo: null,
  assetsListInfo: null,
  productDetailInfo: null,
  lead: null,
  lead_id: null,
  searchBranch: null,
  searchDepartment: null,
  searchCurrentTracking: null,
  searchLocation: null,
  searchProduct: null,
  searchAssets: null,
  is_logged_in: false,
  is_logged_out: false,
  loading: false,
  creating: false,
  isCheckinEligible: false,
  isCheckOutEligible: false,
  updating: false,
  removing: false,
  displayAddLeadDrawer: false,
  displayAddAssetsDrawer: false,
  displayUpdateAssetsDrawer: false,
  displayProductViewDrawer: false,
  displayAddTrackingDrawer: false,
  displayAssetHistoryDrawer: false,
}

export default function trackingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_PRODUCT_VIEW:
      return {
        ...state,
        selectedData: action.payload?.productInfo,
        displayProductViewDrawer: true,
      }
    case actions.HIDE_PRODUCT_VIEW:
      return { ...state, selectedData: null, displayProductViewDrawer: false }

    case actions.SHOW_CREATE:
      return { ...state, displayAddAssetsDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddAssetsDrawer: false }

    case actions.SHOW_CREATE_TRACKING:
      return { ...state, displayAddTrackingDrawer: true }
    case actions.HIDE_CREATE_TRACKING:
      return {
        ...state,
        displayAddTrackingDrawer: false,
        assetDetailInfo: null,
        productDetailInfo: null,
        lastCheckInInfo: null,
        myLastAction: null,
        isCheckinEligible: false,
      }

    default:
      return state
  }
}
