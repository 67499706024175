import apiClient from 'services/axios'

const BASE_URL = 'user'

export async function login(payload) {
  return apiClient
    .post(`${BASE_URL}/login-user`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function recover(payload) {
  return apiClient
    .post(`${BASE_URL}/recover`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset(payload) {
  return apiClient
    .post(`${BASE_URL}/reset`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changepassword(updatedData, _id) {
  return apiClient
    .put(`${BASE_URL}/changepassword/${_id}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
