export default async function getMenuData() {
  return [
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Manage Masters',
      key: 'manage-masters',
      icon: 'fe fe-user',
      url: '/manage/masters',
    },
    {
      title: 'Manage Assets',
      key: 'manage-assets',
      icon: 'fe fe-user',
      url: '/manage/assets',
    },
    {
      title: 'Asset Tracking',
      key: 'asset-tracking',
      icon: 'fe fe-user',
      url: '/manage/asset-tracking',
    },
    {
      title: 'Timeline View',
      key: 'timeline-view',
      icon: 'fe fe-user',
      url: '/manage/timeline-view',
    },
  ]
}
