const actions = {
  SET_STATE: 'tracking/SET_STATE',
  LOAD_BRANCH: 'tracking/LOAD_BRANCH',
  LOAD_DEPARTMENT: 'tracking/LOAD_DEPARTMENT',
  LOAD_LOCATION: 'tracking/LOAD_LOCATION',
  LOAD_PRODUCT: 'tracking/LOAD_PRODUCT',
  LOAD_ASSETS: 'tracking/LOAD_ASSETS',
  SHOW_PRODUCT_VIEW: 'tracking/SHOW_PRODUCT_VIEW',
  HIDE_PRODUCT_VIEW: 'tracking/HIDE_PRODUCT_VIEW',
  SHOW_CREATE: 'tracking/SHOW_CREATE',
  HIDE_CREATE: 'tracking/HIDE_CREATE',
  GET_PRODUCT_INFO: 'tracking/GET_PRODUCT_INFO',
  LOAD_PRODUCT_DETAILS: 'tracking/LOAD_PRODUCT_DETAILS',
  LOAD_ASSET_DETAILS: 'tracking/LOAD_ASSET_DETAILS',
  GET_ASSET_DETAILS: 'tracking/GET_ASSET_DETAILS',
  CREATE_ASSETS: 'tracking/CREATE_ASSETS',
  SHOW_CREATE_TRACKING: 'tracking/SHOW_CREATE_TRACKING',
  HIDE_CREATE_TRACKING: 'tracking/HIDE_CREATE_TRACKING',
  GET_ASSETS_INFO: 'tracking/GET_ASSETS_INFO',
  CREATE_TRACKING: 'tracking/CREATE_TRACKING',
  LOAD_TRACKING: 'tracking/LOAD_TRACKING',
  GET_ASSET_HISTORY: 'tracking/GET_ASSET_HISTORY',
  LOAD_ASSET_TRACKING: 'tracking/LOAD_ASSET_TRACKING',
  LOAD_CURRENT_TRACKING: 'tracking/LOAD_CURRENT_TRACKING',
}

export default actions
