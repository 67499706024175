import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// import LanguageSwitcher from './LanguageSwitcher'
import { Tag } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectUser } from 'redux/user/selectors'
import style from './style.module.scss'
import UserMenu from './UserMenu'

const mapStateToProps = () =>
  createStructuredSelector({
    user: selectUser,
  })

const TopBar = ({ user }) => {
  return (
    <div className={style.topbar}>
      <div className="mr-4" />
      <div className="mr-auto" />
      <div className="mr-4 d-none d-md-block" />
      <div className="mb-0 mr-auto d-xl-block d-none" />
      {/* <div className="mr-4 d-none d-sm-block" style={{display:'none'}}>
        <LanguageSwitcher />ss
      </div> */}
      <div className="mr-4 d-none d-sm-block">
        <span style={{ fontWeight: 'bold' }}>Logged in as:</span>&nbsp;
        {user?.name}
        &nbsp;
        <Tag
          color={(() => {
            switch (user?.userRole) {
              case 'user':
                return 'red'
              case 'superadmin':
                return 'blue'
              default:
                return 'green'
            }
          })()}
          style={{ textTransform: 'capitalize' }}
        >
          {user?.userRole}
        </Tag>
        {/* <img
          src="resources/images/logo2.png"
          alt=""
          style={{ width: '50px', border: '1px solid #f2f2f2' }}
        /> */}
      </div>

      <div className="mr-4 d-none d-sm-block" />
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(TopBar))
