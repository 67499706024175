import apiClient from 'services/axios'

const BASE_URL = 'common'

export async function loadbranch() {
  return apiClient
    .get(`${BASE_URL}/branch-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loaddepartment() {
  return apiClient
    .get(`${BASE_URL}/department-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadlocation() {
  return apiClient
    .get(`${BASE_URL}/location-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadproduct() {
  return apiClient
    .get(`${BASE_URL}/product-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAssets() {
  return apiClient
    .get(`${BASE_URL}/assets-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadtracking() {
  return apiClient
    .get(`${BASE_URL}/get-tracking-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadProductInfo() {
  return apiClient
    .get(`${BASE_URL}/get-product`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadProductDetail(payload) {
  return apiClient
    .post(`${BASE_URL}/get-product-detail`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAssetDetail(payload) {
  return apiClient
    .post(`${BASE_URL}/get-asset-detail`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveassets(payload) {
  return apiClient
    .post(`${BASE_URL}/create-asset`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAssetsInfo() {
  return apiClient
    .get(`${BASE_URL}/get-asset`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAssetTracking() {
  return apiClient
    .get(`${BASE_URL}/get-timeline-report`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createTracking(payload) {
  return apiClient
    .post(`${BASE_URL}/create-tracking`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadassethistory(payload) {
  return apiClient
    .post(`${BASE_URL}/get-asset-history`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadCurentTracking() {
  return apiClient
    .get(`${BASE_URL}/get-current-tracking-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
